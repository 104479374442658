// @ts-ignore
import ReactQuery from "@tanstack/react-query";
import Events from "./Events";
import "./style.scss";

const { QueryClient, QueryClientProvider } = ReactQuery;

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Events />
    </QueryClientProvider>
  );
};

export default App;
