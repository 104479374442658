// @ts-nocheck
import useEvents from "./useEvents";
import { useState, useRef } from "react";
import { getSuffix } from "./utils";

const Events = () => {
  const [date, setDate] = useState(null);
  const formattedDate = date?.toLocaleString("en-US", {
    month: "long",
    year: "numeric",
  });

  const { data: events = [], isLoading } = useEvents({
    date: date?.toISOString().substr(0, 10),
  });
  const dateRef = useRef();

  return (
    <div id="events_filter_app">
      <span className="datepicker-toggle">
        <span className="datepicker-toggle-button"></span>
        <input
          type="date"
          className="datepicker-input"
          ref={dateRef}
          onFocus={() => {
            dateRef.current.showPicker();
          }}
          onChange={(el) => {
            setDate(new Date(el.target.value));
          }}
        />
      </span>

      <div className="event_list">
        {!date && <div className="message">Pick a date</div>}
        {isLoading && <div className="message">Loading events...</div>}
        {date && !isLoading && <div className="message">{formattedDate}</div>}
        {date && !isLoading && events.length === 0 && (
          <div className="message no-data">No events found</div>
        )}
        {events
          .sort((a, b) => b.date - a.date)
          .map((event) => {
            const date = new Date(event.date);
            const day = date.toLocaleDateString("en-US", { day: "numeric" });
            const month = date.toLocaleDateString("en-US", { month: "short" });
            const suffix = getSuffix(date.getDate());

            return (
              <div key={event.id} className="event">
                <div className="event_date">
                  <div className="event_date_box">
                    <div>
                      <span className="event_date_day">{day}</span>
                      <span className="event_date_suffix">{suffix}</span>
                    </div>
                    <span className="event_date_month">{month}</span>
                  </div>
                </div>
                <div
                  className="event_description"
                  dangerouslySetInnerHTML={{ __html: event.content?.rendered }}
                ></div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Events;
