// @ts-nocheck
import { useEffect } from "react";
import useAlerts from "./useAlerts";

const Alerts = () => {
  const { data: alerts = [], isLoading } = useAlerts();

  useEffect(() => {
    if (!isLoading && alerts.length > 0) {
      new Glider(document.querySelector(".alerts-container"), {
        slidesToShow: 1,
        draggable: false,
        arrows: {
          prev: ".glider-prev",
          next: ".glider-next",
        },
      });
    }
  }, [isLoading, alerts]);

  if (alerts.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="alerts-container">
        {alerts.map((alert) => {
          return (
            <a
              key={alert.id}
              className="alert-message"
              href={alert.acf.link?.url}
              target={alert.acf.link?.target}
            >
              <h3>{alert.title.rendered}</h3>
            </a>
          );
        })}
      </div>
      <div className="glider-next">{">"}</div>
      <div className="glider-prev">{"<"}</div>
    </div>
  );
};

export default Alerts;
