// @ts-nocheck
import "./style.scss";

jQuery(window).ready(() => {
  jQuery(".locations-row").each(function () {
    jQuery(this)
      .children()
      .each(function () {
        const id = jQuery(this).attr("id");
        if (id) {
          jQuery(document)
            .on("mouseenter", `#${id}`, function (event) {
              jQuery(`#${id}-pin`).addClass("pin-highlighted");
              jQuery(`#${id}`).addClass("loc-highlighted");
            })
            .on("mouseleave", `#${id}`, function () {
              jQuery(`#${id}-pin`).removeClass("pin-highlighted");
              jQuery(`#${id}`).removeClass("loc-highlighted");
            });
        }
      });
  });
});
