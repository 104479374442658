// @ts-nocheck
import ReactQuery from "@tanstack/react-query";

const WP_API_URL = "/wp-json/wp/v2";

const useEvents = ({ date }) => {
  return ReactQuery.useQuery({
    queryKey: ["events", date],
    queryFn: () => {
      if (date) {
        return fetch(
          `${WP_API_URL}/events?orderby=date&order=desc&after=${date}T00:00:00&before=${date}T23:59:59`
        ).then((response) => response.json());
      }
      return [];
    },
  });
};

export default useEvents;
