// @ts-ignore
import ReactQuery from "@tanstack/react-query";

const getSuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return "th";
  }

  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const getYearsArray = ({ startYear, endYear }) => {
  // const startYear = 2020;

  // get the current year
  // const currentYear = new Date().getFullYear();

  // create an empty array to store the year strings
  const years = [];

  // use a loop to iterate over the years and add them to the array
  for (let year = startYear; year <= endYear; year++) {
    years.push(year.toString());
  }
  return years;
};

export { ReactQuery, getSuffix, getYearsArray };
