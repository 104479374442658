// @ts-nocheck
import EventsApp from "./event-filter";
import AlertsApp from "./alerts";
import "./office-locations";

const eventsRootEl = document.getElementById("events");
if (eventsRootEl) {
  ReactDOM.render(<EventsApp />, eventsRootEl);
}

const alertsRootEl = document.getElementById("alerts");
if (alertsRootEl) {
  ReactDOM.render(<AlertsApp />, alertsRootEl);
}