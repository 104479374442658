// @ts-nocheck
import ReactQuery from "@tanstack/react-query";
import Alerts from "./Alerts";
import "./style.scss";

const { QueryClient, QueryClientProvider } = ReactQuery;

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Alerts />
    </QueryClientProvider>
  );
};

export default App;
