// @ts-nocheck
import ReactQuery from "@tanstack/react-query";

const WP_API_URL = "/wp-json/wp/v2";

const useAlerts = () => {
  return ReactQuery.useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["alerts"],
    queryFn: () => {
      return fetch(`${WP_API_URL}/alerts`).then((response) => response.json());
    },
  });
};

export default useAlerts;
